const makeOwnBtn = document.querySelector(".make-own");
const buyAllBtn = document.querySelector(".buy-all");

const makeOwnWrapper = document.querySelector(".make-own-wrapper");
const buyAllWrapper = document.querySelector(".buy-all-wrapper");

if (makeOwnBtn && buyAllBtn) {
  makeOwnBtn.addEventListener("click", () => {
    makeOwnBtn.classList.add("active");
    buyAllBtn.classList.remove("active");
    makeOwnWrapper.classList.toggle("hidden");
    buyAllWrapper.classList.toggle("hidden");
  });

  buyAllBtn.addEventListener("click", () => {
    makeOwnBtn.classList.remove("active");
    buyAllBtn.classList.add("active");
    makeOwnWrapper.classList.toggle("hidden");
    buyAllWrapper.classList.toggle("hidden");
  });
}
